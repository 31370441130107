<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <router-link to="/annonces">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="updateannonce()" novalidate>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Nom <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="annonce.nom"
                        type="text"
                        class="form-control"
                        placeholder="nom"
                        id="nom"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Type <span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="exampleSelect"
                        v-model="annonce.type"
                        @change="onSelectChange(type)"
                      >
                        <option value="Article">Article</option>
                        <option value="Projet">Projet</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    id="quantite"
                    v-if="annonce.type == 'Article'"
                    style="display: block"
                  >
                    <div class="form-group">
                      <label for="last"
                        >Quantite <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="annonce.quantite"
                        type="number"
                        class="form-control"
                        placeholder="quantite"
                        id="last"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Date <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="annonce.date"
                        type="date"
                        class="form-control"
                        placeholder=""
                        id="date"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Animatrice <span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="exampleSelect"
                        v-model="annonce.animatrice_id"
                      >
                        <option
                          v-for="user in animatrices"
                          :key="user._id"
                          v-bind:value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Délégué <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="annonce.delegue_id"
                        required
                      >
                        <option
                          v-for="del in delegues"
                          :key="del._id"
                          v-bind:value="del._id"
                        >
                          {{ del.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Client <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="annonce.client_id"
                        required
                      >
                        <option
                          v-for="del in clients"
                          :key="del._id"
                          v-bind:value="del._id"
                        >
                          {{ del.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!--  col-md-6   -->

                
                </div>

                <button type="submit" class="btn btn-primary">
                  Modifier projet
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { HTTP } from "@/axios";
import moment from "moment";
export default {
  data() {
    return {
      annonce: {},

      delegues: [],
      clients: [],
      animatrices: [],
    };
  },
  created: function () {
    this.getannonceById();
    this.getdelegue();
    this.getclientss();
    this.getanimatrice();
  },
  methods: {
    getdelegue() {
      HTTP.get("annonces/getdelegue")
        .then((response) => {
          console.log(response.data);
          this.delegues = response.data;
        })
        .then((err) => console.log(err));
    },
    getclientss() {
      HTTP.get("annonces/getclientss")
        .then((response) => {
          console.log(response.data);
          this.clients = response.data;
        })
        .then((err) => console.log(err));
    },
    getanimatrice() {
      HTTP.get("annonces/getanimatrice")
        .then((response) => {
          console.log(response.data);
          this.animatrices = response.data;
        })
        .then((err) => console.log(err));
    },
    getannonceById() {
      HTTP.get("annonces/getannoncebyid/" + this.$route.params.id).then(
        (res) => {
          this.annonce = res.data;

          this.annonce.type = this.annonce.type;
          this.annonce.quantite = this.annonce.quantite;
          this.annonce.nom = this.annonce.nom;
          this.annonce.client_id = this.annonce.client_id["_id"];
          this.annonce.animatrice_id = this.annonce.animatrice_id["_id"];
          this.annonce.delegue_id = this.annonce.delegue_id["_id"];
          console.log(this.annonce.animatrice_id);

          this.annonce.date = moment(this.annonce.date).format("YYYY-MM-DD");
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },
    updateannonce() {
      HTTP.put("annonces/updateannonce/", this.annonce).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "annonce modifié",
            });
            this.$router.push("/annonces");
          }
        },
        (err) => {
          this.error = err.response.data.error;
          console.log(this.error);
        }
      );
    },

    onSelectChange(data) {
      if (data === "Article") {
        document.getElementById("quantite").style.display = "block";
      } else {
        document.getElementById("quantite").style.display = "none";
      }
    },
  },
};
</script>
